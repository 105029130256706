<template>
  <v-dialog
    class="dialog-height"
    persistent
    scrollable
    :value="true"
    :width="width"
    @click-outside="closeNotPersistentModal"
  >
    <v-card class="delete-modal">
      <v-card-text class="df-flex-xl df-flex-col delete-modal__text">
        <div class="df-flex-l df-flex-col align-center">
          <v-img
            height="66"
            width="66"
            src="icons/ic-warning-green.svg"
            alt="warning"
          />
          <v-btn class="modal__close-icon" icon @click="closeModal">
            <v-icon> mdi-close </v-icon>
          </v-btn>
          <div class="df-flex-sm df-flex-col align-center modal__message">
            <h2>{{ title }}</h2>
            <p>{{ text }}</p>
          </div>
        </div>
        <slot name="content"></slot>
      </v-card-text>
      <v-divider />
      <v-card-actions class="df-flex-l justify-end modal__actions">
        <v-btn class="btn-back" height="auto" plain text @click="closeModal">
          {{ $t('back') }}
        </v-btn>
        <v-btn class="btn-action ml-0" height="auto" plain text @click="action">
          {{ $t('excluir') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteModal',

  props: {
    persistent: {
      default: false,
      type: Boolean,
    },
    text: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    width: {
      default: 430,
      type: Number,
    },
  },

  methods: {
    action() {
      this.$emit('action-click')
    },
    closeNotPersistentModal() {
      if (!this.persistent) this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-height {
  max-height: 75%;
}
.delete-modal {
  .delete-modal__text {
    padding: 32px 24px !important;

    @include d(m) {
      padding: 16px 12px !important;
    }

    .modal__close-icon {
      position: absolute;
      top: 32px;
      right: 24px;
    }
    .modal__message {
      h2 {
        color: #1a2b46;
        text-align: center;
        font-family: 'Rubik';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      p {
        color: #5b6459;
        text-align: center;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .modal__actions {
    padding: 16px 24px;
    .btn-back {
      background-color: #ffffff;
      border: 1px solid #e6e9e6;
      padding: 16px 12px;
    }
    .btn-action {
      background-color: #f23528;
      padding: 16px 12px;
    }
  }
}
::v-deep .btn-back .v-btn__content,
::v-deep .btn-action .v-btn__content {
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1a2b46;
  text-transform: capitalize;
  opacity: 1 !important;
}
::v-deep .btn-action .v-btn__content {
  color: #ffffff;
}
</style>
